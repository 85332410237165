<template>
  <div class="error-page">
    <AppHeader class="container" />
    <div class="error-page__wrapper">
      <NuxtImg
        class="error-page__image"
        src="/_nuxt/404.png"
        alt="Strona nie została odnaleziona"
      />
      <h1 class="error-page__header">Strona nie została odnaleziona</h1>
      <a
        role="button"
        href="https://dobrymechanik.pl/"
      >
        <button class="button button--primary">Wróć na stronę główną</button>
      </a>
    </div>

    <AppFooter class="container" />
  </div>
</template>

<script
  setup
  lang="ts"
>


</script>

<style lang="scss">
.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px 0;


  &__image {
    width: 100%;
    max-width: 800px
  }

  &__wrapper {
    width: 100%;
    padding: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px 0;
  }

  &__header {
    font-weight: 500;
    margin: 0 0 10px 0;
    font-size: 22px;

    @media (min-width: $breakpoint) {
      font-size: 32px;
    }
  }

}
</style>