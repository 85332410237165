export default defineNuxtPlugin(nuxtApp => {
  window.addEventListener('onetrustloaded', () => {
    const OneTrust = window.OneTrust;

    const onetrustActiveGroups = window.OnetrustActiveGroups.split(',').filter((el: any) => el.length);

    const hasPerformanceConsent = !!onetrustActiveGroups.find(id => id === 'C0002');

    const { $posthog } = useNuxtApp();

    const persistence = hasPerformanceConsent ? 'localStorage+cookie' : 'memory';

    $posthog()?.set_config({ persistence });
  });
});


// Strictly necessary cookies ID C0001: the website needs these cookies in order to function properly (example: identify items placed into a shopping cart)

// Performance cookies ID C0002: get information about how site visitors are using the website (example: Google analytics)

// Functional cookies ID C0003: provide additional enhancement of the experience of site visitors (example: language selector for localization)

// Targeting cookies ID C0004: cookies that attempt to gather more information about a user in order to personalize marketing (example: remarketing)

// Social media cookies ID C0005: social media services added to the site that enable users to share content with their friends and networks easily
