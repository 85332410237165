import { defineNuxtPlugin } from '#app';
import posthog from 'posthog-js';
import { getCookie } from '@/helpers/cookie';

export default defineNuxtPlugin(nuxtApp => {

  function hasConsentCookieAccepted() {
    const name = 'OptanonConsent';
    const regex = new RegExp(`(^| )${name}=([^;]+)`);
    const match = document.cookie.match(regex);
    if (match) {
      const params = Object.fromEntries(new URLSearchParams(match[2]));
      if (params.groups !== undefined) {
        return params.groups.search('C0002:1') !== -1;
      }
      return false;
    }
    return false;
  }

  const runtimeConfig = useRuntimeConfig();
  const posthogClient = posthog.init(runtimeConfig.public.posthogPublicKey, {
    api_host: runtimeConfig.public.posthogHost || 'https://app.posthog.com',
    persistence: (hasConsentCookieAccepted()) ? 'localStorage+cookie' : 'memory',
    capture_pageview: false, // we add manual pageview capturing below
    loaded: (posthog) => {
      const posthogDistinctId = getCookie('posthog_distinct_id');
      if (posthogDistinctId) posthog.identify(posthogDistinctId);
      if (import.meta.env.MODE === 'development') posthog.debug();
    }
  });

  return {
    provide: {
      posthog: () => posthogClient
    }
  };
});
