import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as index53BLzLSH1oMeta } from "/app/pages/mapa/index.vue?macro=true";
import { default as indexE8CrvNznqHMeta } from "/app/pages/mechanicy/[city]/[garageId]/index.vue?macro=true";
import { default as _91serviceId_93ix8RYB2VlNMeta } from "/app/pages/mechanicy/[city]/[garageId]/services/[serviceId].vue?macro=true";
import { default as indexGYzDwtA7SGMeta } from "/app/pages/mechanicy/[city]/[garageId]/services/index.vue?macro=true";
export default [
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index53BLzLSH1oMeta?.name ?? "mapa",
    path: index53BLzLSH1oMeta?.path ?? "/mapa",
    meta: index53BLzLSH1oMeta || {},
    alias: index53BLzLSH1oMeta?.alias || [],
    redirect: index53BLzLSH1oMeta?.redirect || undefined,
    component: () => import("/app/pages/mapa/index.vue").then(m => m.default || m)
  },
  {
    name: indexE8CrvNznqHMeta?.name ?? "mechanicy-city-garageId",
    path: indexE8CrvNznqHMeta?.path ?? "/mechanicy/:city()/:garageId()",
    meta: indexE8CrvNznqHMeta || {},
    alias: indexE8CrvNznqHMeta?.alias || [],
    redirect: indexE8CrvNznqHMeta?.redirect || undefined,
    component: () => import("/app/pages/mechanicy/[city]/[garageId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91serviceId_93ix8RYB2VlNMeta?.name ?? "mechanicy-city-garageId-services-serviceId",
    path: _91serviceId_93ix8RYB2VlNMeta?.path ?? "/mechanicy/:city()/:garageId()/services/:serviceId()",
    meta: _91serviceId_93ix8RYB2VlNMeta || {},
    alias: _91serviceId_93ix8RYB2VlNMeta?.alias || [],
    redirect: _91serviceId_93ix8RYB2VlNMeta?.redirect || undefined,
    component: () => import("/app/pages/mechanicy/[city]/[garageId]/services/[serviceId].vue").then(m => m.default || m)
  },
  {
    name: indexGYzDwtA7SGMeta?.name ?? "mechanicy-city-garageId-services",
    path: indexGYzDwtA7SGMeta?.path ?? "/mechanicy/:city()/:garageId()/services",
    meta: indexGYzDwtA7SGMeta || {},
    alias: indexGYzDwtA7SGMeta?.alias || [],
    redirect: indexGYzDwtA7SGMeta?.redirect || undefined,
    component: () => import("/app/pages/mechanicy/[city]/[garageId]/services/index.vue").then(m => m.default || m)
  }
]