<template>
  <NuxtLayout>
    <NuxtPage />
    <DmDialog ref="dialogRef" />
    <DmNotification ref="notificationRef" />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { DmDialog, DmNotification } from '@dobrymechanik/vue-ui-v2';
import { provide, ref } from 'vue';

// data 
const dialogRef: Ref<any> | undefined = ref(null);
const notificationRef: Ref<any> | undefined = ref(null);

// provide
provide('$dialog', dialogRef);
provide('$notification', notificationRef);

</script>

<style lang="scss">
@import 'assets/styles/global/index.scss';

body {
  margin: 0;
  padding: 0;
  background-color: $white;
  width: 100%;
}

div,
input,
button {
  box-sizing: border-box;
  margin: 0;
}

a:-webkit-any-link {
  text-decoration: none;
}

.loader {
  z-index: 15;
}

.dialog {
  z-index: 14;
}

.notification {
  z-index: 15;
}

strong {
  font-weight: 500;
}
</style>