<template>
  <div class="dropdown-button-wrapper">
    <button
      class="dropdown-button"
      :title="title"
      :class="classesArray"
      :disabled="disabled"
      @click="onShow"
    >
      <div v-if="hasIcon === 'only' && icon">
        <i :class="icon" />
      </div>
      <div
        v-else
        class="dropdown-button__icon"
      >
        <i
          v-if="hasIcon === 'left' && icon"
          :class="icon"
          class="mr-2"
        />
        <span class="button__title">{{ title }}</span>
        <i
          v-if="hasIcon === 'right' && icon"
          :class="icon"
          class="ml-2"
        />
      </div>
    </button>


    <transition name="fade">
      <div
        v-show="show"
        class="dropdown-button-background"
      >

      </div>
    </transition>

    <transition name="slide">
      <div
        v-show="show"
        @focusout="close"
        @click="close"
        tabindex="0"
        ref="dropdownButtonRef"
        class="dropdown-button-slot"
      >
        <div class="dropdown-button-slot__header">
          <div>{{ header }}</div>
          <div
            tabindex="0"
            class="dropdown-button-slot__close-wrapper"
            @click="close"
            @keyup.enter="close"
          >
            <i class="dropdown-button-slot__close i-close" />
          </div>
        </div>
        <slot />
      </div>
    </transition>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { ref, computed } from 'vue';

enum ButtonHasIcon {
  Only = 'only',
  No = 'no',
  Left = 'left',
  Right = 'right'
}

interface IProps {
  title: string;
  header: string;
  secondary?: boolean;
  tertiary?: boolean;
  disabled?: boolean;
  icon?: string;
  hasIcon?: string;
}

// data
const show: Ref<boolean> = ref(false);
const dropdownButtonRef: Ref<HTMLElement | null> = ref(null);

// props 
const props = defineProps<IProps>();

// computed
const classesArray = computed(() => {
  const classes: string[] = [];
  if (props.secondary) {
    classes.push('button--secondary');
  } else if (props.tertiary) {
    classes.push('button--tertiary');
  } else {
    classes.push('button--primary');
  }
  return classes;
});

// methods 
const onShow = () => {
  show.value = true;

  setTimeout(() => {
    (dropdownButtonRef.value as HTMLElement).focus();
  }, 0);
};

const close = (e: any) => {
  if (!e.relatedTarget) {
    show.value = false;
  }
}

</script>

<style
  lang="scss"
  scoped
>
.dropdown-button-wrapper {
  position: relative;
}

.dropdown-button-background {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: $black;
  opacity: 0.5;

  @media (min-width: $breakpoint) {
    display: none;
  }
}

.dropdown-button-slot {
  position: fixed;
  bottom: 0;
  background: $white;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 101;
  background-color: $white;
  box-shadow: 2px 2px 10px 0px rgba(140, 142, 170, 0.30);
  border-radius: 15px 15px 0 0;
  border: 1px solid $grey-4;

  &:focus {
    outline: none;
  }

  @media (min-width: $breakpoint) {
    position: absolute;
    top: calc(46px + 10px);
    left: unset;
    bottom: unset;
    border-radius: 10px;
    padding: unset;
  }


  &__header {
    display: flex;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    justify-content: space-between;
    align-items: center;
    padding: 24px 14px 20px 14px;

    @media (min-width: $breakpoint) {
      display: none;
    }
  }

  &__close-wrapper {
    position: absolute;
    right: 14px;
    width: 30px;
    height: 30px;
    background-color: rgba(118, 118, 118, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 15px;

    &:focus {
      outline-offset: 3px;
      outline-color: $blue-3;
      outline-width: 2px;
    }
  }

  &__close {
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    background-color: black !important;
  }
}

.dropdown-button {
  min-height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 400;
  box-sizing: border-box;
  font-size: 16px;

  &__icon {
    display: flex;
    flex-wrap: nowrap;

    span {
      display: none;
    }

    @media (min-width: $breakpoint) {
      i {
        display: none;
      }

      span {
        display: unset;
      }
    }
  }

  &__title,
  div {
    pointer-events: none;
    text-wrap: nowrap;
    white-space: nowrap;
  }

  &__title {
    display: none;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease-in-out, opacity 0.3s linear;

  @media (min-width: $breakpoint) {
    transition: unset !important;
  }
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(100%);

  @media (min-width: $breakpoint) {
    opacity: unset !important;
    transform: unset !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s linear;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>