<template>
  <footer
    class="app-footer"
    id="app-footer"
    itemscope
    itemtype="http://schema.org/Organization"
  >
    <div class="app-footer__container">
      <div class="app-footer__row">
        <div class="footer-info">
          <a
            class="app-footer__brand"
            href="https://dobrymechanik.pl/"
            data-gtm-element="logo-footer"
          >
            <NuxtImg
              src="/_nuxt/logo.svg"
              alt="DobryMechanik.pl logo"
              height="35"
              width="124"
            />
          </a>
          <p class="app-footer__info-text">Znajdź najlepszego mechanika!</p>
          <span
            itemprop="legalName"
            content="DobryMechanik.pl Sp. z o.o."
          ></span>
          <span
            itemprop="url"
            content="https://dobrymechanik.pl/"
          ></span>
          <span
            itemprop="logo"
            content="https://dobrymechanik.pl/img/main/logo-dobrymechanik@2x.png?_stv=1705933810"
          ></span>
          <div
            itemprop="brand"
            itemscope
            itemtype="http://schema.org/Brand"
          >
            <span
              itemprop="name"
              content="DobryMechanik.pl Sp. z o.o."
            ></span>
          </div>
        </div>
      </div>
      <div class="app-footer__wrapper">
        <div class="app-footer__row">
          <p class="app-footer__header">Dla warsztatów</p>
          <ul class="app-footer__links">
            <li><a
                href="https://warsztaty.dobrymechanik.pl"
                data-gtm-link="footer-for-mechanics"
              ><span>Oferta dla warsztatów</span></a></li>
            <li><a
                href="https://www.zilo.co/"
                target="_blank"
              ><span>Program Zilo</span></a></li>
            <li><a
                href="https://blog.dobrymechanik.pl/"
                target="_blank"
              ><span data-gtm-element="footer-for-mechanics">Blog dla mechaników</span></a></li>
            <li><a
                href="https://dobrymechanik.pl/pomoc/pytania/"
                data-gtm-link="footer-for-mechanics"
              ><span>Najczęściej zadawane pytania</span></a></li>
            <li><a
                href="https://dobrymechanik.pl/kontakt/formularz/position:garage"
                data-gtm-link="footer-for-mechanics"
              ><span>Kontakt</span></a></li>
          </ul>
        </div>
        <div class="app-footer__row">
          <p class="app-footer__header">Dla kierowców</p>
          <ul class="app-footer__links">
            <li>
              <a
                href="https://dobrymechanik.pl/mechanicy/"
                data-gtm-link="footer-for-drivers"
              ><span>Mechanicy</span></a>
            </li>
            <li><a
                href="https://dobrymechanik.pl/uslugi/"
                data-gtm-link="footer-for-drivers"
              ><span>Usługi</span></a></li>
            <li><a
                href="https://dobrymechanik.pl/specjalizacje/"
                data-gtm-link="footer-for-drivers"
              ><span>Specjalizacje</span></a></li>
            <li><a
                href="https://dobrymechanik.pl/mapa/"
                data-gtm-link="footer-for-drivers"
              ><span>Warsztaty na mapie</span></a></li>
            <li><a
                href="https://dobrymechanik.pl/artykuly/"
                data-gtm-link="footer-for-drivers"
              ><span>Artykuły</span></a></li>
            <li><a
                href="https://dobrymechanik.pl/encyklopedia-napraw/"
                data-gtm-link="footer-for-drivers"
              ><span>Encyklopedia napraw</span></a></li>
            <li><a
                data-gtm-link="kiosk-polis"
                data-gtm-value="kiosk-polis-footer"
                rel="nofollow"
                href="https://kioskpolis.pl/kalkulator-moto/?partner=mechanik"
                target="_blank"
              >Ubezpieczenia OC/AC</a></li>
            <li><a
                href="https://dobrymechanik.pl/kontakt/formularz/position:driver"
                data-gtm-link="footer-for-drivers"
              ><span>Kontakt</span></a></li>
          </ul>
        </div>
        <div class="app-footer__row">
          <p class="app-footer__header">O portalu</p>
          <ul class="app-footer__links">
            <li><a href="https://dobrymechanik.pl/mapa-strony.html"><span>Mapa strony</span></a></li>
            <li><a href="https://dobrymechanik.pl/pomoc/regulamin/"><span>Regulamin</span></a></li>
            <li><a
                href="https://dobrymechanik.pl/pomoc/cookies/"
                data-gtm-link="footer-about-company"
              ><span>Polityka cookies</span></a></li>
            <li><a
                href="https://dobrymechanik.pl/pomoc/polityka_prywatnosci/"
                data-gtm-link="footer-about-company"
              ><span>Polityka prywatności</span></a></li>
            <li><a
                href="https://dobrymechanik.pl/pomoc/iod/"
                data-gtm-link="footer-iod"
              ><span>Inspektor ochrony danych</span></a></li>
            <li
              role="button"
              @click="cookieSettings"
            >Ustawienia plików cookie</li>
            <li><a
                href="https://dobrymechanik.pl/kontakt/formularz/"
                data-gtm-link="footer-about-company"
              ><span>Kontakt</span></a></li>
          </ul>
        </div>
        <div class="app-footer__row">
          <p class="app-footer__header">Wyróżniamy najlepsze warsztaty</p>
          <div class="app-footer__certificate">
            <a
              href="https://dobrymechanik.pl/pomoc/kierowcy-polecaja/"
              data-gtm-link="footer-kierowcy-polecaja"
            >
              <span>
                <NuxtImg
                  class="garage-wgz"
                  src="/_nuxt/wgz.svg"
                  width="65"
                  height="90"
                  alt="Program Kierowcy Polecają"
                />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <Script type="text/javascript">
  function OptanonWrapper(data) { 
    const oneTrustLoadedEvent = new CustomEvent('onetrustloaded', data);
    window.dispatchEvent(oneTrustLoadedEvent);
  }
</Script>
</template>

<script
  setup
  lang="ts"
>
const cookieSettings = () => {
  const OneTrust = window.OneTrust as any;
  if (OneTrust) {
    OneTrust.ToggleInfoDisplay();
  }
};

</script>

<style
  lang="scss"
  scoped
>
@import url('assets/styles/components/app-footer.scss');
</style>